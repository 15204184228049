import axios from 'axios'
import { TokenService } from './storage.service'

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
        axios.defaults.headers.common["Access-Control-Max-Age"] =600;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type';
        axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
    },

    setHeader() {
        const token = TokenService.getToken();
        axios.defaults.headers.common["Authorization"] = "Bearer " + token.access_token;
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource, params = null) {
        return axios.get(resource, params)
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    patch(resource, data) {
        return axios.patch(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
    **/
    customRequest(data) {
        return axios(data)
    }
}

export { ApiService }