import { ApiService } from './api.service'
import { TokenService } from './storage.service'
import store from '../store/index'
import { LOAD_USER_PROFILE } from '../store/mutation-types'

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService. 
   * role 0 - Member , role 1 - Admin
   * @returns response
   * @throws AuthenticationError 
  **/
  login: async function (credentials, role) {
    let url;
    if (role == 0) {
      url = 'auth/login';
    } else if (role == 1) {
      url = 'admin/login'
    }

    try {
      const response = await ApiService.post(url, credentials);
      let data = response.data;
      let gender = 0;

      if (data.gender == "පුරුෂ") {
        gender = process.env.VUE_APP_MALE_VALUE;
      } else if (data.gender == "ස්ත්‍රී") {
        gender = process.env.VUE_APP_FEMALE_VALUE;
      }

      if (role == 0) {
        if (data.approved && !data.isPackageExpired) {
          const token = { access_token: data.access_token, gen: gender, av: btoa(data.photo ? data.photo : "") }; // Token for members
          TokenService.saveToken(token);
          ApiService.setHeader();
        }
      } else if (role == 1) {
        TokenService.saveToken(data);
        ApiService.setHeader();
      }

      return response;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  // Logout
  logout: async function (role) {
    let url;
    if (role == 0) {
      url = 'auth/logout';
    } else if (role == 1) {
      url = 'admin/logout' // need to updated
    }

    try {
      const response = await ApiService.post(url);
      TokenService.removeToken();
      return response;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  // Get user payload from jwt token
  getUserPayload: function () {
    var token = TokenService.getToken();
    if (token) {
      var userPayload = atob(token.access_token.split('.')[1]);
      return JSON.parse(userPayload);
    } else {
      return null;
    }
  },

  // Check whether user is logged in or not
  isLoggedIn: function () {
    var userPayload = this.getUserPayload();
    if (userPayload) {
      return userPayload.exp > Date.now() / 1000;
    } else {
      return false;
    }
  },

  // Check whether user is an admin
  isAdmin: function () {
    var userPayload = this.getUserPayload();
    if (userPayload && userPayload.admin) {
      return true;
    } else {
      return false;
    }
  }

  // Check whether user is logged in or not
  // isLoggedIn: async function (role) {
  //   let url = 'auth/check';

  //   if (role == 1) {
  //     url = 'admin/check';
  //   }

  //   try {
  //     const response = await ApiService.get(url);
  //     if (response.data) {
  //       return true;
  //     }
  //   } catch (error) {
  //     return false;
  //   }
  // },
}

export { UserService, AuthenticationError }