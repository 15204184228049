import Vue from 'vue'
import VueRouter from 'vue-router'
import { UserService } from '../services/user.service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home'),
    meta: {
      public: true  // Allow access to even not logged in
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/layouts/RegistrationLayout'),
    meta: {
      public: true,  // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/not-approved',
    name: 'notApproved',
    component: () => import('../views/NotApproved'),
    meta: {
      public: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: () => import('../views/TermsAndConditions'),
    meta: {
      public: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy'),
    meta: {
      public: true
    }
  },
  {
    path: '/package-expired',
    name: 'packageExpired',
    component: () => import('../views/PackageExpired'),
    meta: {
      public: true
    }
  },
  {
    path: '/dashboard',
    name: 'mainLayout',
    redirect: '/dashboard/members',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/layouts/MainLayout'),
    meta: {
      onlyForMembers: true
    },
    children: [
      {
        path: 'members',
        name: 'members',
        component: () => import('../views/Members')
      },
      {
        path: 'members/:id',
        name: 'profile',
        component: () => import('../views/Profile')
      },
      {
        path: 'photo-requests',
        name: 'photoRequests',
        component: () => import('../views/Requests')
      },
      {
        path: 'horoscope-requests',
        name: 'horoscopeRequests',
        component: () => import('../views/Requests')
      },
      {
        path: 'horoscope-match',
        name: 'HoroscopeMatch',
        component: () => import('../views/HoroscopeMatch')
      },
      {
        path: 'my-profile',
        name: 'myProfile',
        component: () => import('../views/MyProfile')
      },
      {
        path: 'favourites',
        name: 'favourites',
        component: () => import('../views/Favourites')
      },
      {
        path: 'package',
        name: 'package',
        component: () => import('../views/Package')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/Settings')
      },
    ]
  },
  {
    path: '/login',
    name: 'adminLogin',
    component: () => import('../components/admin/Login'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/aw-admin',
    name: 'awAdmin',
    redirect: '/aw-admin/dashboard',
    component: () => import('../components/layouts/MainLayout'),
    meta: {
      onlyForAdmin: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'adminDashboard',
        component: () => import('../views/admin/Dashboard')
      },
      {
        path: 'new-members',
        name: 'newMembers',
        component: () => import('../views/admin/NewMembers')
      },
      {
        path: 'approved-members',
        name: 'approvedMembers',
        component: () => import('../views/admin/ApprovedMembers')
      },
      {
        path: 'suspended-members',
        name: 'suspendedMembers',
        component: () => import('../views/admin/SuspendedMembers')
      },
      {
        path: 'new-members/:id',
        name: 'newMember',
        component: () => import('../views/admin/Member')
      },
      {
        path: 'approved-members/match/:id',
        name: 'matchedProposals',
        component: () => import('../views/admin/MatchedProposals')
      },
      {
        path: 'approved-members/:id',
        name: 'approvedMember',
        component: () => import('../views/admin/Member')
      },
      {
        path: 'suspended-members/:id',
        name: 'suspendedMember',
        component: () => import('../views/admin/Member')
      },
      {
        path: 'advertisements',
        name: 'advertisements',
        component: () => import('../views/admin/Advertisements')
      },
      {
        path: 'package-requests',
        name: 'packageRequests',
        component: () => import('../views/admin/PackageRequests')
      },
      {
        path: 'packages',
        name: 'packages',
        component: () => import('../views/admin/Packages')
      },
      {
        path: 'package-usage',
        name: 'packageUsage',
        component: () => import('../views/admin/PackageUsage')
      },
      {
        path: 'settings',
        name: 'adminSettings',
        component: () => import('../views/admin/Settings')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const onlyForAdmin = to.matched.some(record => record.meta.onlyForAdmin);
  const onlyForMembers = to.matched.some(record => record.meta.onlyForMembers);

  const isLoggedIn = UserService.isLoggedIn();
  const isAdmin = UserService.isAdmin();

  // If route is private and user is not logged in redirect to '/'
  if (!isPublic && !isLoggedIn) {
    return next({
      path: '/',
      // query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (onlyWhenLoggedOut && isLoggedIn) {
    if (isAdmin) {
      return next('/aw-admin');
    }
    return next('/dashboard');
  }

  if (onlyForAdmin && !isAdmin) {
    return next('/login');
  }

  if (onlyForMembers && isAdmin) {
    return next('/aw-admin');
  }

  next();
})

export default router