export const state = {
  alert: {
    value: false,
    message: '',
    duration: 3000,
    type: undefined,
    color: '',
    dismissible: true,
    elevation: 24,
    icon: undefined,
    transition: 'fade-transition',
    minWidth: 240
  },
  metaData: {
    provinces: [],
    races: [],
    religions: [],
    casts: [],
    marriageStatuses: [],
    educations: [],
    jobs: [],
    monthlyIncomes: []
  },
  regForm: {
    // Step 1
    gender: "",
    dob: new Date().toISOString().substr(0, 10),
    livingCountry: "",
    province: "",
    district: "",
    town: "",
    race: "",
    religion: "",
    cast: "",
    marriageStatus: "",
    numberOfChildren: "",
    respOfChildren: false,
    educationLevel: "",
    job: "",
    foreignJobStatus: "",
    designation: "",
    incomeValue: "",
    // Step 2
    height: "",
    skinColor: "",
    liquireUsage: "",
    brothersCount: "",
    sistersCount: "",
    assets: false,
    assetsDetails: '',
    otherDetails: '',
    // Data : Step 3
    fullName: "",
    address: "",
    password: "",
    mobileNumber: "",
    secondaryMobileNumber: "",
    // Data : Step 4
    profilePicture: "",
    photoPassword: "",
    profPicShowStatus: 0,
    // Data : Step 5
    nic: "",
    nicFrontPhoto: "",
    nicBackPhoto: "",
    // Data : Step 6
    birthCountry: "",
    birthPlace: "",
    birthTime: "",
    lagna: "",
    ganaya: "",
    nekatha: "",
    ravi: 0,
    chandra: 0,
    kuja: 0,
    budha: 0,
    guru: 0,
    rahu: 0,
    sikuru: 0,
    shani: 0,
    kethu: 0,
    // Step 7
    finder: {
      races: [],
      religions: [],
      casts: [],
      jobs: [],
      districts: [],
      marriageStatuses: [],
      educationLevels: [],
      monthlyIncomes: [],
      ageRange: [18, 30]
    },
    sent: [],
    received: [],
  },
  profilePicture: '',
  progress: false,
  dataTableFooterProps: { 'items-per-page-options': [5, 10, 15, 20], 'items-per-page-text': 'පිටුවකට පේළි' }
};