export const getters = {
  // getDistrictsByProvince: (state) => {
  //   const province = state.regForm.province;
  //   const filter = province.substring(province.length-2, province.length);
  //   if (filter && state.metaData.districts) {
  //     return state.metaData.districts.filter(district => district.startsWith(filter));
  //   }
  // },
  // getTownsByDistrict: (state) => {
  //   const district = state.regForm.district;
  //   const filter = district.substring(district.length-2, district.length);
  //   if (filter && state.metaData.towns) {
  //     return state.metaData.towns.filter(town => town.startsWith(filter));
  //   }
  // }
};