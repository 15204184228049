import { LOAD_USER_PROFILE, FETCH_META_DATA, LOAD_PROFILE_PICTURE } from "./mutation-types";
import { ApiService } from "../services/api.service";
import { TokenService } from "../services/storage.service";
import { UserService } from "../services/user.service";

export const actions = {
  async register({ commit, state }) {
    try {
      const response = await ApiService.post('customers/create', state.regForm);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async loadUserProfile({ commit }) {
    if (UserService.isLoggedIn() && !UserService.getUserPayload().admin) {
      try {
        const url = 'auth/me';
        const response = await ApiService.post(url);
        commit(LOAD_USER_PROFILE, response.data);
      } catch (error) {
        console.log(error);
      }
    }
  },

  async fetchMetaData({ commit }) {
    try {
      const url = "metadata";
      const response = await ApiService.get(url);
      commit(FETCH_META_DATA, response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadProfilePicture({ commit }) {
    const token = await TokenService.getToken();
    const file = atob(token.av);
    if (file) {
      try {
        const url = "files/download";
        const params = { params: { file: file } };
        const response = await ApiService.get(url, params);
        commit(LOAD_PROFILE_PICTURE, process.env.VUE_APP_BASE_URL + response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      if (token.gen == process.env.VUE_APP_MALE_VALUE) {
        commit(LOAD_PROFILE_PICTURE, require("@/assets/images/male_user.png"));
      } else {
        commit(LOAD_PROFILE_PICTURE, require("@/assets/images/female_user.png"));
      }
    }
  }
};