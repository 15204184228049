import {
  SHOW_ALERT, HIDE_ALERT, LOAD_USER_PROFILE, FETCH_META_DATA, UPDATE_FINDER_PROPERTIES,
  UPDATE_REG_FORM, RESET_REGISTRATION_FORM, CHANGE_LOADING_STATE, LOAD_PROFILE_PICTURE
} from './mutation-types'

const initialRegForm = {
  // Step 1
  gender: "",
  dob: new Date().toISOString().substr(0, 10),
  livingCountry: "",
  province: "",
  district: "",
  town: "",
  race: "",
  religion: "",
  cast: "",
  marriageStatus: "",
  numberOfChildren: "",
  respOfChildren: false,
  educationLevel: "",
  job: "",
  foreignJobStatus: "",
  designation: "",
  monthlyIncome: "",
  incomeValue: "",
  // Step 2
  height: "",
  skinColor: "",
  liquireUsage: "",
  brothersCount: "",
  sistersCount: "",
  assets: false,
  assetsDetails: '',
  otherDetails: '',
  // Data : Step 3
  fullName: "",
  address: "",
  password: "",
  mobileNumber: "",
  secondaryMobileNumber: "",
  // Data : Step 4
  profilePicture: "",
  photoPassword: "",
  profPicShowStatus: true,
  // Data : Step 5
  nic: "",
  nicFrontPhoto: "",
  nicBackPhoto: "",
  // Data : Step 6
  birthCountry: "",
  birthPlace: "",
  birthTime: "",
  lagna: "",
  ganaya: "",
  nekatha: "",
  ravi: 0,
  chandra: 0,
  kuja: 0,
  budha: 0,
  guru: 0,
  rahu: 0,
  sikuru: 0,
  shani: 0,
  kethu: 0,
  // Step 7
  finder: {
    races: [],
    religions: [],
    casts: [],
    jobs: [],
    districts: [],
    marriageStatuses: [],
    educationLevels: [],
    monthlyIncomes: [],
    ageRange: [18, 30]
  },
  sent: [],
  received: [],
}

export const mutations = {
  [SHOW_ALERT](state, payload) {
    state.alert.value = true;
    state.alert.message = payload.message ? payload.message : '';
    state.alert.type = payload.type ? payload.type : '';
    state.alert.duration = payload.duration ? payload.duration : 3000;
  },

  [HIDE_ALERT](state) {
    state.alert.value = false;
  },

  [LOAD_USER_PROFILE](state, payload) {
    state.user = payload;
  },

  [FETCH_META_DATA](state, payload) {
    state.metaData = payload;
  },

  [UPDATE_REG_FORM](state, payload) {
    state.regForm[payload.property] = payload.value;
  },

  [UPDATE_FINDER_PROPERTIES](state, payload) {
    if (payload.value.includes("නොසැලකේ")) {
      state.regForm.finder[payload.property] = [];
      state.regForm.finder[payload.property] = ["නොසැලකේ"];
    } else {
      state.regForm.finder[payload.property] = payload.value;
    }
  },

  [RESET_REGISTRATION_FORM](state) {
    state.regForm = initialRegForm;
  },

  [CHANGE_LOADING_STATE](state, payload) {
    state.progress = payload.active;
  },

  [LOAD_PROFILE_PICTURE](state, payload) {
    if (payload) {
      state.profilePicture = payload;
    } else {
      state.profilePicture = "";
    }
  }
};